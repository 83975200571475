import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";

export const NotFound = styled(
  ({ fallback, ...props }: { fallback?: boolean }) => {
    const router = useRouter();

    return (
      <div {...props}>
        <div>
          <b>404</b> | This page could not be found.{" "}
          {fallback ? (
            <a onClick={() => router.back()}>Return Back.</a>
          ) : (
            <Link href="/">Return to the homepage.</Link>
          )}
        </div>
      </div>
    );
  }
)`
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: var(--text-color);
  flex-grow: 1;

  a {
    color: var(--text-color);
    text-decoration: underline;
  }
`;

export default NotFound;
